import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Trans, Link } from "gatsby-plugin-react-i18next"
import classnames from "classnames"
import { Helmet } from "react-helmet"

import Header from "./header"
import "../stylesheets/styles.scss"

const Layout = ({ children, variant }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <>
        <Helmet>
          <script
            id="ze-snippet"
            src="https://static.zdassets.com/ekr/snippet.js?key=81a6ae10-58ac-46a1-bd7c-63b5a73d7578"
          />
          {/* https://cookieconsent.popupsmart.com/#simple-cookie-consent */}
          <script type="text/javascript" src="https://cookieconsent.popupsmart.com/src/js/popper.js" />
          <script>{`
            window.start.init({
              Palette: "palette2",
              Mode: "banner bottom",
              Theme: "classic",
              Location: "/privacy",
              Time: "5",
            })
          `}</script>
        </Helmet>
      </>
      <Header siteTitle={data.site.siteMetadata.title} variant={variant} />

      <main className={classnames("main", { "home ": variant === "home" })}>
        {children}
      </main>

      <footer className="footer">
        <p>
          Copyright © 2020 - {new Date().getFullYear()} <i>by</i>{" "}
          <strong>tiramizoo GmbH</strong>, all rights reserved.
          <span>
            <Link to="/imprint">
              <Trans>footer.link-imprint</Trans>
            </Link>
            |
            <a href="https://tiramizoo.com/tiramizoo-agb-kunden-2019.pdf">
              <Trans>footer.link-terms</Trans>
            </a>
            |
            <Link to="/privacy">
              <Trans>footer.link-privacy</Trans>
            </Link>
          </span>
        </p>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
