import PropTypes from "prop-types"
import React from "react"
import { Link, Trans, useI18next } from "gatsby-plugin-react-i18next"
import classnames from "classnames"

import logo from "images/lmm-logo.svg"

const Header = ({ siteTitle, variant }) => {
  const { language, languages, changeLanguage } = useI18next()

  const handleChangeLanguage = (e, lng) => {
    e.preventDefault()
    changeLanguage(lng)
  }

  return (
    <nav
      className={classnames("navbar", { "home-header ": variant === "home" })}
    >
      <Link to="/" className="navbar__brand">
        <img src={logo} alt="Last Mile Master" />
      </Link>

      <input id="navbar__toggle" type="checkbox" />
      <label htmlFor="navbar__toggle" className="navbar__toggle-on">
        <span></span>
        <span></span>
        <span></span>
      </label>

      <div className="navbar__main">
        <label htmlFor="navbar__toggle" className="navbar__toggle-off">
          <span></span>
          <span></span>
        </label>

        <Link to="/" className="navbar__brand">
          <img src={logo} alt="Route optimizer logo" />
        </Link>

        <ul>
          <li>
            <Link to="/features" className="navbar__link">
              <Trans>navigation.features</Trans>
            </Link>
          </li>
          <li>
            <div class="navbar__lang-switch">
              {languages.map(lng => (
                <div key={lng}>
                  <a
                    className={classnames({ active: language === lng })}
                    href="#"
                    onClick={e => handleChangeLanguage(e, lng)}
                  >
                    {lng}
                  </a>
                </div>
              ))}
            </div>
          </li>
        </ul>
      </div>
    </nav>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  variant: PropTypes.oneOf(["default", "home"]),
}

Header.defaultProps = {
  siteTitle: ``,
  variant: "default",
}

export default Header
